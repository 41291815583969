import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f867f430"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post" }
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.post.image_url+'?anonymous',
      title: _ctx.post.pic_text,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInModal()))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("small", null, _toDisplayString(_ctx.toDate(_ctx.post.date_time_posted)) + " - " + _toDisplayString(_ctx.getPictureDescription(_ctx.post.pic_text)), 1)
    ])
  ]))
}