export default {
  toDate(text: string): string {
    const monthsNames = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];

    const date = new Date(text);
    return `${monthsNames[date.getMonth()]} ${date.getFullYear()}`;
  },

  // filter out default text
  getPictureDescription(text: string): string {
    return text.indexOf("Photo by mARTa Taschen") >= 0 ? "mARTa Taschen" : text;
  },

  encodedEmailAddress: "bWFydGEyMEBibHVld2luLmNo",
};
